<template>
  <v-card>
    <v-card-title><h4>Users</h4></v-card-title>
    <v-data-table
      :headers="headers"
      :items="items.items"
      :server-items-length="items.meta.total"
      :loading="loading"
      :options.sync="options"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on">
                <v-icon dense small>{{ icons.mdiPlus }}</v-icon
                >&nbsp;New User
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="edit">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="First name"
                          required
                          v-model="editedItem.first_name"
                          :error-messages="firstNameErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.last_name"
                          label="Last name"
                          required
                          :error-messages="lastNameErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.email"
                          type="email"
                          label="Email"
                          required
                          :error-messages="emailErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="editedItem.organization_id"
                          :items="orgObjects.items"
                          item-text="name"
                          item-value="id"
                          label="Organizations"
                          required
                          :error-messages="organizationErrors"
                        >
                        </v-select>
                      </v-col>
                      <v-col v-if="editedIndex === -1" cols="12">
                        <v-text-field
                          v-model="editedItem.password"
                          label="Password"
                          type="password"
                          required
                          :error-messages="passwordErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-combobox
                          v-model="editedItem.roles"
                          :items="roles"
                          label="Role"
                          multiple
                          chips
                          required
                          :error-messages="roleErrors"
                          ><template v-slot:selection="data">
                            <v-chip
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                              :color="getColor(data.item)"
                            >
                              {{ data.item }}
                            </v-chip>
                          </template>
                        </v-combobox>
                      </v-col>
                      <v-col cols="12">
                        <v-switch
                          v-model="editedItem.is_active"
                          flat
                          label="Active"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="save"> Save </v-btn>
                <v-btn color="secondary" outlined @click="close">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="400px">
            <v-card>
              <v-card-title class="text-h5 text-break text-center"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.email="{ item }">
        <a :href="item.email">{{ item.email }}</a>
      </template>
      <template v-slot:item.roles="{ item }">
        <v-chip
          v-for="role in item.roles"
          :key="role.name"
          :color="getColor(role.name)"
        >
          {{ role.name }}
        </v-chip>
      </template>
      <template v-slot:item.organization_id="{ item }">
        {{ organizations[item.organization_id] }}</template
      >
      <template v-slot:item.is_active="{ item }">
        <v-simple-checkbox
          v-model="item.is_active"
          disabled
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          {{ icons.mdiPencil }}
        </v-icon>
        <v-icon small @click="deleteItem(item)"> {{ icons.mdiDelete }} </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getDataFromApi"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mdiPencil, mdiPlus, mdiDelete } from "@mdi/js"
import { mapActions, mapGetters, mapState } from "vuex"
import { validationMixin } from "vuelidate"
import { required, email, minLength } from "vuelidate/lib/validators"

export default {
  mixins: [validationMixin],
  //TODO Implement guard
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.guard(to, next, ["manage_users", "edit_users"])
    })
  },

  validations() {
    if (this.editedIndex == -1) {
      return {
        editedItem: {
          first_name: { required },
          last_name: { required },
          email: { required, email },
          organization_id: { required },
          password: { required, minLength: minLength(8) },
          roles: { required },
          is_active: { required },
        },
      }
    } else {
      return {
        editedItem: {
          first_name: { required },
          last_name: { required },
          email: { required, email },
          organization_id: { required },
          roles: { required },
          is_active: { required },
        },
      }
    }
  },
  data() {
    return {
      loading: true,
      total: 0,
      options: {},

      dialog: false,
      dialogDelete: false,

      editedIndex: -1,
      editedItem: {
        id: -1,
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        roles: ["User"],
        is_active: false,
      },
      defaultItem: {
        id: -1,
        first_name: "",
        last_name: "",
        email: "",
        roles: ["User"],
        is_active: false,
      },
      icons: { mdiPencil, mdiPlus, mdiDelete },
    }
  },
  computed: {
    headers() {
      return [
        { text: "First name", value: "first_name" },
        { text: "Last name", value: "last_name" },
        { text: "Email", sortable: false, value: "email" },
        { text: "Organization", sortable: false, value: "organization_id" },
        { text: "Role(s)", sortable: false, value: "roles" },
        { text: "Active", value: "is_active", align: "center" },
        { text: "Actions", sortable: false, value: "actions", align: "center" },
      ]
    },
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User"
    },
    roles() {
      var roles = this.roleObjects.items.map(item => item.name)
      if (!this.guardAny(["manage_auth"])) {
        roles.splice(roles.indexOf("Super Admin"), 1)
      }
      return roles
    },
    organizations() {
      return this.orgObjects.items.reduce(
        (a, v) => ({ ...a, [v.id]: v.name }),
        {}
      )
    },
    userType() {
      var type =
        this.editedItem.roles.indexOf("Psychiatrist") != -1
          ? "pysyquiatrist"
          : "patient"
      type = this.editedItem.roles.indexOf("Admin") != -1 ? "admin" : type
      return type
    },
    firstNameErrors() {
      const errors = []
      if (!this.$v.editedItem.first_name.$dirty) return errors
      !this.$v.editedItem.first_name.required &&
        errors.push("First name is required.")
      return errors
    },
    lastNameErrors() {
      const errors = []
      if (!this.$v.editedItem.last_name.$dirty) return errors
      !this.$v.editedItem.last_name.required &&
        errors.push("Last name is required.")
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.editedItem.email.$dirty) return errors
      !this.$v.editedItem.email.email && errors.push("Must be valid e-mail")
      !this.$v.editedItem.email.required && errors.push("E-mail is required.")
      return errors
    },
    organizationErrors() {
      const errors = []
      if (!this.$v.editedItem.organization_id.$dirty) return errors
      !this.$v.editedItem.organization_id.required &&
        errors.push("Organization is required.")
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.editedItem.password.$dirty) return errors
      !this.$v.editedItem.password.minLength &&
        errors.push("Password must be at least 8 characters long")
      !this.$v.editedItem.password.required &&
        errors.push("Password is required.")
      return errors
    },
    roleErrors() {
      const errors = []
      if (!this.$v.editedItem.roles.$dirty) return errors
      !this.$v.editedItem.roles.required &&
        errors.push("You should have a least a role.")
      return errors
    },
    ...mapState({
      items: state => state.users.users,
      roleObjects: state => state.users.roles,
      orgObjects: state => state.organizations.organizations,
    }),
    ...mapGetters("users", ["guardAny"]),
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    getColor(role) {
      switch (role) {
        case "Super Admin":
        case "Admin":
          return "error"
        case "Psychiatrist":
          return "success"
        case "Manager":
          return "warning"
      }
      return "secondary"
    },

    getDataFromApi() {
      this.loading = true
      this.$nextTick(() => {
        const start = (this.options.page - 1) * this.options.itemsPerPage
        this.loadUsers({ start: start, limit: this.options.itemsPerPage }).then(
          () => {
            this.loading = false
          }
        )
      })
    },

    editItem(item) {
      this.editedIndex = this.items.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.roles = this.editedItem.roles.map(item => item.name)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.items.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.loading = true
      this.deleteUser(this.editedItem.id).then(() => {
        this.loading = false
      })
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$v.$reset()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.loading = true
        this.editedItem.type = this.userType
        if (this.editedIndex > -1) {
          this.editUser(this.editedItem).then(() => {
            this.getDataFromApi()
          })
        } else {
          this.addUser(this.editedItem).then(() => {
            this.getDataFromApi()
          })
        }
        this.close()
      }
    },
    async guard(to, next, permissions) {
      if (this.guardAny(permissions)) {
        console.log("Yay!")
      } else {
        console.error("Nay!")
      }
    },
    ...mapActions("users", [
      "loadUsers",
      "loadRoles",
      "addUser",
      "editUser",
      "deleteUser",
    ]),
    ...mapActions("organizations", ["loadOrganizations"]),
  },
  mounted() {
    this.loadRoles({ limit: -1 })
    this.loadOrganizations({ limit: -1 })
  },
}
</script>
